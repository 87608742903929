import React, { useState } from "react"

const Steps = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const increaseStepTo = ({ step }) => {
    if (step >= currentStep) {
      setCurrentStep(step)
    }
  }

  const renderedWrapper = children({ increaseStepTo })
  const filteredWrapper = React.cloneElement(
    renderedWrapper,
    undefined,
    renderedWrapper.props.children.filter((child, i) => i <= currentStep)
  )

  return filteredWrapper
}

export default Steps
