/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Box from "./box"
import Label from "./label"
import Stack from "./stack"
import FormMessage from "./form-message"

const Textarea = React.forwardRef((props, ref) => {
  const { bold, height, id, label, message, readOnly, tone, ...restProps } =
    props

  return (
    <Stack space={2}>
      {label && (
        <Label htmlFor={id} bold={bold}>
          {label}
        </Label>
      )}
      <Box
        as="textarea"
        id={id}
        ref={ref}
        readOnly={readOnly}
        {...restProps}
        sx={{
          appearance: "none",
          display: "block",
          width: "100%",
          fontSize: "inherit",
          fontFamily: "sans",
          lineHeight: "inherit",
          minHeight: height,
          border: "1px solid",
          borderColor: tone === "negative" ? "negative" : "border",
          borderRadius: "md",
          color: readOnly ? "whisper" : "inherit",
          bg: readOnly ? "rgba(0,0,0,0.05)" : "transparent",
          cursor: readOnly ? "text" : null,
          p: 2,
          resize: "vertical",
          variant: "forms.textarea",
        }}
      />
      {message && <FormMessage tone={tone}>{message}</FormMessage>}
    </Stack>
  )
})

Textarea.propTypes = {
  /**
   * unique id for the textarea. when a label is provided this will automatically
   * be used as the `for` attribute
   */
  id: PropTypes.string.isRequired,
  /**
   * Label for the textarea. Make sure to skip this only when you are providing
   * an alternative label (and have a good reason to break our design
   * conventions around labels on top of inputs/textareas)
   */
  label: PropTypes.node,
  /**
   * minimum height of the textarea
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * this controls wether the label should be bold or not
   */
  bold: PropTypes.bool,
  /**
   * optional message which is displayed beneath the input. used for error
   * messages and instructions
   */
  message: PropTypes.string,
  /**
   * text color and icon are based on the tone of the message
   */
  tone: PropTypes.oneOf(["positive", "neutral", "negative"]),
}

Textarea.defaultProps = {
  height: 12,
  bold: true,
}

export default Textarea
